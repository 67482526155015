import { ComplianceStepType, useKycRequest } from '@ping/api';
import DriverLicenseIcon from '@ping/assets/Icon/driver-license.svg';
import IdCardIcon from '@ping/assets/Icon/id-card.svg';
import LockIcon from '@ping/assets/Icon/lock.svg';
import PassportIcon from '@ping/assets/Icon/passport.svg';
import { t } from '@ping/helpers';
import { useCountdown } from '@ping/hooks';
import { entityTypeDeclarationModalRemoteStateSelector, useModalStore } from '@ping/stores/modal.store';
import { Divider, PageSpinner, Toast } from '@ping/uikit';
import clsx from 'clsx';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { KycCardLayout } from './KycCardLayout';
import { VerificationQrCode } from '../VerificationCard/VerificationQrCode';
import { KycCheckStatusModalTrigger } from './KycCheckStatusModalTrigger';
import { KycWatchTutorialModalTrigger } from './KycWatchTutorialModalTrigger';
import style from './style.module.scss';
import {
  complianceStepSelector,
  documentExpirationDateSelector,
  userInformationStore,
  useUserInformationStore,
} from '@ping/stores/userInformation.store';
import { useNotificationWS } from '@ping/websockets';
import { KycNotificationStatus, NotificationEvents } from '@ping/websockets/useNotificationWS';
import { IS_DEVELOPMENT_MODE } from '@ping/configs';
import * as gtm from '@ping/helpers/gtm.helper';
import type { ErrorType } from '@ping/api/custom-instance';
import { sortObject } from '@ping/utils';
import { VerificationExpired } from './VerificationExpired';

interface IKycCardContentCheckStatus {
  onOpenModal: () => void;
  kycRequestMutation: () => void;
  errorMessageData: object;
  isRequestProcessing: boolean;
  isKycLoading: boolean;
  documentExpirationDate: boolean;
}

const KYC_TOAST_MESSAGES_CONTENT = new Map()
  .set(KycNotificationStatus.Accepted, {
    type: 'progress',
    title: t('Data request accepted...'),
    description: t("Please don't close CorePass while the request is being processed"),
  })
  .set(KycNotificationStatus.InitiationSubmitted, {
    type: 'progress',
    title: t('Starting data transfer'),
    description: t("Please don't close CorePass while the request is being processed"),
  })
  .set(KycNotificationStatus.InitiationFailed, {
    type: 'error',
    title: t('Data transfer failed'),
    description: null,
  })
  .set(KycNotificationStatus.ValidityCheck, {
    type: 'progress',
    title: t('Checking integrity of submitted data'),
    description: t("Please don't close CorePass while the request is being processed"),
  })
  .set(KycNotificationStatus.ValidityFailed, {
    type: 'error',
    title: t('Checking integrity of submitted data failed'),
    description: null,
  })
  .set(KycNotificationStatus.Confirmed, {
    type: 'progress',
    title: t('Confirming data transfer...'),
    description: t("Please don't close CorePass while the request is being processed"),
  })
  .set(KycNotificationStatus.ConfirmFailed, {
    type: 'error',
    title: t('Confirming data transfer failed'),
    description: null,
  });

export function KycCard() {
  const [showQrStep, setShowQrStep] = useState(false);
  const [isRequestProcessing, setIsRequestProcessing] = useState(false);
  const complianceStep = useUserInformationStore(complianceStepSelector);
  const lastToastId = useRef('pendingDataToastId');
  const isExpirationTimeExpired = useRef(false);
  const shownQrcodeCount = useRef<number>(0);
  const startKycTime = useRef<number>(0);

  const [errorMessageData, setErrorMessageData] = useState<object>({
    FullName: false,
    DOB: false,
    AdditionalImage: false,
    DocumentNumber: false,
    ExpiryDate: false,
    DocumentImage: false,
    FaceImage: false,
    Country: false,
    AML_Check: false,
    AML_Detail: false,
    Email: false,
    Address_Country: false,
    Address_City: false,
    Address_DocumentImage: false,
    Address_ExpiryDate: false,
    Address_FULLNAME: false,
    Address_State: false,
    Address_Street: false,
    Address_Street2: false,
    Address_ZipCode: false,
  });
  const entityTypeDeclarationModalRemoteState = useModalStore(entityTypeDeclarationModalRemoteStateSelector);
  const documentExpirationDate = useUserInformationStore(documentExpirationDateSelector);

  const {
    mutate: kycRequestMutation,
    data: kycResponseData,
    isLoading: isKycLoading,
  } = useKycRequest({
    mutation: {
      onSuccess: value => {
        const expirationTimeInMillisecond = value?.expiration * 1000;
        isExpirationTimeExpired.current = expirationTimeInMillisecond - new Date().getTime() < 0;
        shownQrcodeCount.current = shownQrcodeCount.current + 1;
        if (!startKycTime.current) {
          startKycTime.current = new Date().getTime();
        }
        // (Backend/CorePass bug)
        // With the new CorePass changes, it is highly that this bug will not happen again: (86bwq7vz3)
        // The issue we are experiencing is likely caused by getting expired time from the Backend/CorePass.
        // Therefore, for safety reasons, we check whether the value we receive from the backend has expired or not.

        if (isExpirationTimeExpired.current) {
          Toast.error({
            title: t('Something went wrong!'),
            text: t('Please try again later'),
          });
          setShowQrStep(false);
        } else {
          setShowQrStep(true);
          Toast.progress({
            title: t('Waiting for KYC data'),
            text: t("Please don't close CorePass while the request is being processed"),
            options: { autoClose: false, toastId: 'pendingDataToastId' },
          });
        }
      },
      retry: (failureCount, error: ErrorType<any>) => {
        if (axios.isCancel(error) || error?.response?.status === 422) {
          return false;
        }
        return failureCount < 3;
      },

      onError: error => {
        // TODO (Backend): We get the wrong type from the backend
        const errorResponse = (error?.response?.data as Record<'errors', [{ data: any }]>)?.errors[0]?.data;
        const errorCode = (error?.response?.data as Record<'errorCode', number>)?.errorCode;

        // (Backend/CorePass bug)
        // Check the error data only if we come across error 422 (errorCode 10001)
        if (errorCode === 10001) {
          if (errorResponse) {
            setErrorMessageData(sortObject(errorResponse, true));
          } else {
            // (Backend/CorePass bug)
            // Show the first step if the data is null
            setShowQrStep(false);
            setIsRequestProcessing(true);
            Toast.error({
              title: t('Unable to send new request'),
              text: t('You already have a request in process'),
              options: { toastId: 'ongoingRequestToastId' },
            });
          }
        } else {
          Toast.error({
            title: (error?.response?.data as Record<'message', string>)?.message,
          });
        }
      },
    },
  });

  const timer = useCountdown(kycResponseData?.expiration * 1000);

  const { notificationData, notificationCallBack } = useNotificationWS();

  notificationCallBack?.(NotificationEvents.OnKycStatusChanged);

  useEffect(() => {
    if (IS_DEVELOPMENT_MODE) {
      // We need to keep this log in the production for the debugging.
      console.log('KYC Notification Status => ', notificationData);
    }
    if (notificationData?.status === KycNotificationStatus.PingReceivedKyc) {
      const kycDuration = (new Date().getTime() - startKycTime.current) / 1000;
      gtm.kycQrcodeEvent(shownQrcodeCount, kycDuration);
      userInformationStore.setComplianceStep(ComplianceStepType.FormA);
      Toast.dismiss(lastToastId.current);
      if (showQrStep) {
        Toast.dismiss('ongoingRequestToastId');
      }
    }

    if (KYC_TOAST_MESSAGES_CONTENT.has(notificationData?.status)) {
      Toast.dismiss(lastToastId.current);
      lastToastId.current = notificationData?.status;
      const toastData = KYC_TOAST_MESSAGES_CONTENT.get(notificationData?.status);
      Toast[toastData.type]({
        title: toastData.title,
        text: toastData.description,
        options: { autoClose: false, toastId: notificationData?.status },
      });
    }
  }, [notificationData]);

  useEffect(() => {
    // Remove all the QR step Toast messages when we go back to the first step
    if (!showQrStep) {
      Toast.dismiss('pendingDataToastId');
      Toast.dismiss(lastToastId.current);
    }

    return () => {
      if (showQrStep) {
        Toast.dismiss('pendingDataToastId');
        Toast.dismiss(lastToastId.current);
      }
    };
  }, [showQrStep]);

  useEffect(() => {
    const isTimeExpired = Boolean(timer.minutes + timer.seconds <= 0);

    // Call the API only in the first step or when the time is expired.
    if (!isExpirationTimeExpired.current && (isTimeExpired || !showQrStep)) kycRequestMutation();
  }, [showQrStep, timer.seconds, isExpirationTimeExpired]);

  useEffect(() => {
    if (complianceStep === ComplianceStepType.EntityTypeDeclaration) {
      entityTypeDeclarationModalRemoteState.open();
    }
  }, [complianceStep]);

  return (
    <KycCardLayout
      HeaderIcon={<LockIcon />}
      headerTitle={t('Unlock Trading')}
      HeaderContainerChild={showQrStep && <KycWatchTutorialModalTrigger />}
    >
      {isKycLoading && <PageSpinner />}

      {showQrStep && (
        <VerificationQrCode
          isVerificationInProgress={isKycLoading}
          quickResponse={kycResponseData}
          timer={timer}
          isLoading={[
            KycNotificationStatus.Accepted,
            KycNotificationStatus.Initiated,
            KycNotificationStatus.InitiationSubmitted,
            KycNotificationStatus.ValidityCheck,
            KycNotificationStatus.ValiditySucceeded,
            KycNotificationStatus.Confirmed,
            KycNotificationStatus.ConfirmSubmitted,
          ].includes(notificationData?.status as KycNotificationStatus)}
        />
      )}

      {!showQrStep && (
        <KycCardContentCheckStatus
          onOpenModal={kycRequestMutation}
          kycRequestMutation={kycRequestMutation}
          errorMessageData={errorMessageData}
          isRequestProcessing={isRequestProcessing}
          isKycLoading={isKycLoading}
          //  TODO (Backend): This part will be changed once the Backend team merges this merge request: https://git.energy/ping/frontoffice/-/merge_requests/634
          documentExpirationDate={new Date(documentExpirationDate).getTime() - new Date().getTime() <= 0}
        />
      )}
    </KycCardLayout>
  );
}

const KycCardContentCheckStatus = ({
  onOpenModal,
  kycRequestMutation,
  isKycLoading,
  errorMessageData,
  isRequestProcessing,
  documentExpirationDate,
}: IKycCardContentCheckStatus) => (
  <>
    <div className={style['kyc-card-content__text']}>
      {documentExpirationDate && <VerificationExpired />}

      <h3 className={style['kyc-card-content__title']}>{t('Verify your identity in the CorePass ID app')}</h3>
      <p className={style['kyc-card-content__text-paragraph']}>
        {t('1. Digitize a government-issued ')}
        <span className={style['kyc-card-content__text-paragraph-white-text']}>{t(' ID document ')}</span>
        {t('of your choice. We support the following document types:')}
      </p>
      <div className={style['kyc-card-content__text-icon-container']}>
        <span className={style['kyc-card-content__text-icon']}>
          <DriverLicenseIcon />
          {t('Driver’s license')}
        </span>
        <span className={style['kyc-card-content__text-icon']}>
          <PassportIcon />
          {t('Passport')}
        </span>
        <span className={style['kyc-card-content__text-icon']}>
          <IdCardIcon />
          {t('National ID card')}
        </span>
      </div>
      <p className={clsx(style['kyc-card-content__text-paragraph'], style['kyc-card-content__step-two-title'])}>
        {t('2. Digitize your street address using ')}
        <span className={style['kyc-card-content__text-paragraph-white-text']}> {t('proof of address ')}</span>
        {t('digitization voucher.')}
      </p>
      <p className={clsx(style['kyc-card-content__text-paragraph'], style['kyc-card-content__step-two-title'])}>
        {t('3. Digitize your ')}
        <span className={style['kyc-card-content__text-paragraph-white-text']}> {t('email address.')}</span>
      </p>

      <Divider className={style['kyc-card-content__divider']} />

      <p className={clsx(style['kyc-card-content__text-paragraph'], style['kyc-card-content__step-two-text'])}>
        {t(
          'After you have completed the digitization the required info, click below to check your status. If you are, not were successfully verified you’ll be asked to complete your Ping Verification.'
        )}
      </p>

      <KycCheckStatusModalTrigger
        onOpen={onOpenModal}
        kycRequestMutation={kycRequestMutation}
        className={style['kyc-card-content__request-btn']}
        isDisabled={isKycLoading}
        isLoading={isKycLoading}
        errorMessageData={errorMessageData}
        isRequestProcessing={isRequestProcessing}
      />
    </div>

    <div className={style['kyc-card-content__image']}>
      <img src={'/img/image-kyc.png'} alt={t('App store image of the Corepass app')} />
    </div>
  </>
);
