import { ComplianceStepType } from '@ping/api';
import { handleProfile } from '@ping/authorization/side-effects';
import { userInformationStore } from '@ping/stores/userInformation.store';
import ArrowDownIcon from '@ping/assets/Icon/arrow-down.svg';
import LockIcon from '@ping/assets/Icon/lock.svg';
import { t } from '@ping/helpers';
import { Card, ScrollButton } from '@ping/uikit';

import { SignFormA } from './SignFormA';
import { SignFormAModalTrigger } from './SignFormAModalTrigger';
import { useState } from 'react';
import { UserActivityStatusModal } from '../UserActivityStatusModal';

export const FormACard = () => {
  const [nextComplianceStep, setNextComplianceStep] = useState<ComplianceStepType>();

  const handleFormASigned = (nextStep: ComplianceStepType) => {
    setNextComplianceStep(nextStep);
    if (nextStep === ComplianceStepType.PendingForReview) {
      handleContinueToNextStep();
    }
  };

  const handleContinueToNextStep = () => {
    userInformationStore.setComplianceStep(nextComplianceStep);
    handleProfile();
  };

  return (
    <Card.Root>
      <Card.Header>
        <Card.LeadingIcon>
          <LockIcon />
        </Card.LeadingIcon>
        <Card.Title>{t('Unlock Trading')}</Card.Title>
      </Card.Header>

      <Card.Divider />

      <Card.Body aria-orientation='vertical'>
        <SignFormA>
          <SignFormAModalTrigger
            onSigned={handleFormASigned}
            aria-details='sign-form-a-required'
            aria-describedby='sign-form-a-warning'
            data-sign-form-a-scroll-to
          />
          <ScrollButton token='data-sign-form-a-scroll-to'>
            <ArrowDownIcon />
            {t('Scroll to end')}
          </ScrollButton>
        </SignFormA>
      </Card.Body>

      <UserActivityStatusModal
        isOpen={
          nextComplianceStep === ComplianceStepType.Trading ||
          nextComplianceStep === ComplianceStepType.Forbidden ||
          nextComplianceStep === ComplianceStepType.PrimaryUse
        }
        nextStep={nextComplianceStep}
        onContinue={handleContinueToNextStep}
      />
    </Card.Root>
  );
};
