import { useScrollTo } from '@ping/hooks';

import { Button } from '../Button';
import style from './style.module.scss';

interface IScrollButtonProps extends ICustomizable {
  /** unique `data-<token>` attribute. need to be present on the target element */
  token: string;
  children: React.ReactNode;
}

export const ScrollButton = (props: IScrollButtonProps) => {
  const scroll = useScrollTo({ token: props.token });

  return (
    <div className={style['scroll-button']} {...scroll.containerProps}>
      <Button className={style['scroll-button__trigger']} type='secondary' {...scroll.buttonProps}>
        {props.children}
      </Button>
    </div>
  );
};
