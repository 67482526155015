import { useSwapTrade } from '@ping/api';
import { DEFAULT_CURRENCY } from '@ping/configs';
import { useState } from 'react';
import { useSelectedCurrency } from './useSelectedCurrency';

export const useConversionAssetToFiat = (assetId: string) => {
  const selectedCurrency = useSelectedCurrency();
  const [rate, setRate] = useState(0);

  useSwapTrade(
    {
      FromAssetId: assetId.toLowerCase(),
      ToAssetId: selectedCurrency.toLowerCase(),
      Amount: 1,
      DefaultCurrency: DEFAULT_CURRENCY.toLowerCase(),
    },
    {
      query: {
        enabled: !!assetId,
        onSuccess: response => {
          setRate(response.rate);
        },
      },
    }
  );

  return { rate, selectedCurrency };
};
