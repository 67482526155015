import { useEffect, useReducer, useRef, useState } from 'react';
import { AssetResponse, PortfolioItemResponse, useGetAllAsset, useGetPortfolio } from '@ping/api';
import { floor } from 'lodash-es';
import { t } from '@ping/helpers';
import { useSelectedCurrency } from '@ping/hooks';
import { AssetSelect, ComboField, NumberField } from '@ping/uikit';
import { format } from '@ping/utils';
import clsx from 'clsx';

import style from './style.module.scss';

const FIVE_MINUTES = 5 * 60 * 1000;
const ONE_DAY = 1 * 60 * 60 * 24 * 1000;

interface IAssetComboBoxProps {
  portfolio?: Map<string, PortfolioItemResponse>;
  defaultAssetId?: string;
  defaultAmount?: number;
  label?: string;
  isDisabled?: boolean;
  onChange?(value: { assetId: string; assetAmount: number }): void;
  filterFn?(asset: AssetResponse, index: number, array: AssetResponse[]): boolean;
  error?: string;
  title?: string;
  className?: string;
  hiddenTitle?: boolean;
}

interface IAssetComboBoxState {
  amount: string;
  assetId: string;
  max: string;
  availableBalance: string;
}

export const AssetComboBox = (props: IAssetComboBoxProps) => {
  //
  // popover stuff
  const boundaryRef = useRef<HTMLDivElement>();
  const [popoverState, emitPopoverStateChange] = useState({ isOpen: false, placement: 'bottom' });

  const selectedCurrency = useSelectedCurrency();

  const assets = useGetAllAsset({
    query: {
      staleTime: ONE_DAY,
      select: ({ data: allAssets }) => {
        return allAssets.reduce((map, asset) => map.set(asset.id, asset), new Map<string, AssetResponse>());
      },
    },
  });

  const { data: portfolio, isLoading: isPortfolioLoading } = useGetPortfolio({
    query: {
      staleTime: FIVE_MINUTES,
      select: ({ portfolioItems }) => {
        return portfolioItems?.reduce(
          (map, item) => map.set(item.assetId, item),
          new Map<string, PortfolioItemResponse>()
        );
      },
    },
  });

  const [state, setState] = useReducer(
    (prev: IAssetComboBoxState, next: Partial<IAssetComboBoxState>) => {
      const current = { ...prev, ...next };

      if (current.assetId !== prev.assetId) {
        current.amount = '';
        current.max = String(
          floor(portfolio.get(current.assetId)?.availableAmount, assets.data?.get(current.assetId)?.scale) ?? ''
        );
        current.availableBalance = String(portfolio.get(current.assetId)?.availableBalance ?? '');
      }

      if (current.assetId !== prev.assetId || current.amount !== prev.amount) {
        props.onChange?.({ assetId: current.assetId, assetAmount: +current.amount });
      }

      return current;
    },
    {
      amount: String(props.defaultAmount ?? ''),
      assetId: '',
      max: '',
      availableBalance: String(portfolio?.get(props.defaultAssetId)?.availableBalance ?? ''),
    }
  );

  useEffect(() => {
    if (assets.data?.get(props.defaultAssetId) && portfolio?.get(props.defaultAssetId)) {
      setState({ assetId: props.defaultAssetId });
    }
  }, [props.defaultAssetId, assets.data?.get(props.defaultAssetId), portfolio?.get(props.defaultAssetId)]);

  return (
    <div className={clsx(style['asset-combo-box'], props.className)}>
      <fieldset>
        {!props.hiddenTitle && (
          <legend className={style['asset-combo-box__label']}>{props.title || t('Amount to send')}</legend>
        )}
        <div ref={boundaryRef}>
          <ComboField
            isDisabled={props.isDisabled}
            data-popover-panel-open={popoverState.isOpen}
            data-popover-panel-placement={popoverState.placement}
          >
            <NumberField
              magnet='end'
              name='assetAmount'
              isDisabled={props.isDisabled || !state.assetId || !state.max}
              minValue='0'
              maxValue={state.max}
              value={state.amount}
              onChange={amount => setState({ amount })}
              formatOptions={{ maximumFractionDigits: assets.data?.get(state.assetId)?.scale }}
            />

            <ComboField.Button
              className={style['asset-combo-box__max']}
              magnet='both'
              isDisabled={props.isDisabled || !state.assetId}
              data-visible={state.amount !== state.max}
              onPress={() => setState({ amount: state.max })}
            >
              {t('MAX')}
            </ComboField.Button>

            <ComboField.Divider />

            <AssetSelect
              className={style['asset-combo-box__select']}
              magnet='start'
              name='assetId'
              boundaryRef={boundaryRef}
              assetId={state.assetId}
              filterFn={props.filterFn || (asset => asset.can_withdraw)}
              onChange={assetId => setState({ assetId })}
              onPanelPlacementChange={placement => emitPopoverStateChange(s => ({ ...s, placement }))}
              onOpenChange={isOpen => emitPopoverStateChange(s => ({ ...s, isOpen }))}
            />
          </ComboField>
        </div>
      </fieldset>

      {props.error && <p className={style['asset-combo-box__error-state']}>{props.error}</p>}

      {!assets.isLoading && !assets.data?.size && (
        <p className={style['asset-combo-box__empty-state']}>{t('No available assets')}</p>
      )}

      <p
        className={style['asset-combo-box__balance']}
        aria-hidden={isPortfolioLoading || !portfolio.has(state.assetId)}
      >
        {!isPortfolioLoading &&
          state.assetId &&
          t(
            '%s Balance: %s (%s)',
            state.assetId.toUpperCase(),
            format.crypto(state.max, state.assetId),
            format.fiat(state.availableBalance, selectedCurrency)
          )}
      </p>
    </div>
  );
};
