import { type PortfolioResponse } from '@ping/api';
import DownChevron from '@ping/assets/Icon/left-chevron.svg';
import { t } from '@ping/helpers';

import { Modal, Text, Spinner, Divider } from '@ping/uikit';

import style from './style.module.scss';
import { format } from '@ping/utils';
import { ECurrency } from '@ping/enums';

interface IBalanceSummaryModalProps {
  portfolio?: PortfolioResponse;
  selectedCurrency: ECurrency;
  isPortfolioLoading: boolean;
}

export const BalanceSummaryModal = ({ portfolio, isPortfolioLoading, selectedCurrency }: IBalanceSummaryModalProps) => {
  return (
    <Modal.Root>
      <Modal.Trigger className={style['balance-summary-modal']}>
        <div className={style['balance-summary-modal__trigger']}>
          <Text body='regular' grayColor>
            {t('Total in portfolio')}
          </Text>
          {isPortfolioLoading ? (
            <Spinner size={8} />
          ) : (
            <div className={style['balance-summary-modal__trigger-info']}>
              <Text heading='3' className={style['portfolio__asset-info_amount']}>
                {format.fiat(portfolio?.totalBalance, selectedCurrency) || 0}
              </Text>
              <DownChevron />
            </div>
          )}
        </div>
      </Modal.Trigger>

      <Modal.Portal className={style['balance-summary-modal__portal']}>
        <Modal.Content className={style['balance-summary-modal__portal-content']}>
          <Modal.Header className={style['balance-summary-modal__portal-header']}>
            <Text heading='4'>{t('Summary')}</Text>
            <Modal.CloseButton className={style['balance-summary-modal__portal-close']} />
          </Modal.Header>

          <div className={style['balance-summary-modal__asset-info']}>
            <Text body='regular' cadetBlueColor>
              {t('Total in portfolio')}
            </Text>
            {isPortfolioLoading ? (
              <Spinner size={8} />
            ) : (
              <Text heading='3'>{format.fiat(portfolio?.totalBalance, selectedCurrency) || 0}</Text>
            )}
          </div>
          <Divider className={style['balance-summary-modal__divider']} />
          <div className={style['balance-summary-modal__asset-info']}>
            <Text body='semi-bold'>{t('Available balance')}</Text>
            <Text body='regular' cadetBlueColor>
              {t(
                'This balance represents the amount of your total balance that is available to buy, sell, send or receive'
              )}
            </Text>
            {isPortfolioLoading ? (
              <Spinner size={8} />
            ) : (
              <Text heading='3' className={style['balance-summary-modal__asset-info_amount']}>
                {format.fiat(portfolio?.availableBalance, selectedCurrency)}
              </Text>
            )}
          </div>
          <Divider className={style['balance-summary-modal__divider']} />
          <div className={style['balance-summary-modal__asset-info']}>
            <Text body='semi-bold'>{t('Holds on orders')}</Text>
            <Text body='regular' cadetBlueColor>
              {t(
                'These are funds currently locked in your active orders. This portion of your balance can’t be used for other transactions until the orders are executed or cancelled.'
              )}
            </Text>
            {isPortfolioLoading ? (
              <Spinner size={8} />
            ) : (
              <Text heading='3' className={style['balance-summary-modal__asset-info_amount']}>
                {`${format.fiat(portfolio?.totalOnHoldBalance, selectedCurrency) || 0}`}
              </Text>
            )}
          </div>
        </Modal.Content>
      </Modal.Portal>
    </Modal.Root>
  );
};
