import clsx from 'clsx';
import React, { useContext } from 'react';

import { CopyableContext } from '../copyable.context';
import CopyIcon from './copy.svg';
import style from './style.module.scss';

export const CopyableIcon = (props: ICustomizable) => {
  const ctx = useContext(CopyableContext);

  if (ctx.isEmpty) return <></>;

  return <CopyIcon className={clsx(style['copyable-icon'], props.className)} />;
};
