import { getOrCreateProfile, useGetOrCreateProfile } from '@ping/api';
import {
  complianceStepSelector,
  userInformationStore,
  useUserInformationStore,
} from '@ping/stores/userInformation.store';
import { UseQueryOptions } from 'react-query';

export const useProfile = (queryOptions?: UseQueryOptions<Awaited<ReturnType<typeof getOrCreateProfile>>>) => {
  const complianceStep = useUserInformationStore(complianceStepSelector);
  const { data, ...rest } = useGetOrCreateProfile(
    {},
    {
      query: {
        ...queryOptions,
        queryKey: ['profile-information', complianceStep],
        onSuccess: data => {
          userInformationStore.setUserInformation(data);
        },
      },
    }
  );

  return {
    data,
    ...rest,
  };
};
