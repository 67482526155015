import { Text } from '@ping/uikit';
import InfoIcon from '@ping/assets/Icon/circle-error.svg';
import { t } from '@ping/helpers';

import style from './style.module.scss';

export const VerificationExpired = () => {
  return (
    <div className={style['verification-expired']}>
      <div className={style['verification-expired__title']}>
        <InfoIcon className={style['verification-expired__title-icon']} />
        <Text body='semi-bold' className={style['verification-expired__title-text']}>
          {t('Verification expired')}
        </Text>
      </div>
      <Text caption='regular' className={style['verification-expired__description']}>
        {t('To continue accessing our platform, we need you to verify your identity again following the steps below.')}
      </Text>
    </div>
  );
};
