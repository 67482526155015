import { useGetFormAUserInfo } from '@ping/api';
import { t } from '@ping/helpers';
import { useTimeoutEffect } from '@ping/hooks';
import { Divider, Loading } from '@ping/uikit';

import style from './style.module.scss';

interface ISignFormAProps {
  children: React.ReactNode;
}

const MILLISECONDS = 1_000 as const;
const THRESHOLD_IN_SECOND = 60 as const;

export const SignFormA = (props: ISignFormAProps) => {
  const formAUserInfo = useGetFormAUserInfo();

  const date = new Date();
  const day = Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
  const month = Intl.DateTimeFormat('en', { month: 'short' }).format(date);
  const year = Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);

  useTimeoutEffect(
    formAUserInfo.refetch,
    formAUserInfo.data?.expirationTime * MILLISECONDS - Date.now() - THRESHOLD_IN_SECOND * MILLISECONDS
  );

  return (
    <section className={style['sign-form-a']}>
      <div className={style['sign-form-a__wrap']}>
        <h3 className={style['sign-form-a__title']} aria-details='sign-form-a-required sign-form-a-warning'>
          {t('Declaration of identity of the beneficial owner')}
        </h3>

        <p className={style['sign-form-a__description']} id='sign-form-a-required'>
          {t('Form A is')}&nbsp;
          <strong className={style['sign-form-a__emphasis']} tabIndex={0}>
            {t('required to be signed with Corepass')}
          </strong>
          {t(', Please, read it thoroughly and agree in order to proceed:')}
        </p>
      </div>

      <Divider />

      {formAUserInfo.isLoading && <Loading />}

      {!formAUserInfo.isLoading && (
        <>
          <dl className={style['sign-form-a__information']}>
            <div className={style['sign-form-a__information-group']}>
              <div className={style['sign-form-a__wrap']}>
                <dt className={style['sign-form-a__information-title']} tabIndex={0}>
                  {t('Account / Securities account number:')}
                </dt>
                <dd className={`${style['sign-form-a__information-value']} ${style['corepass-id']}`}>
                  {formAUserInfo.data?.corePassId}
                </dd>
              </div>

              {formAUserInfo.data?.contractingPartner && (
                <div className={style['sign-form-a__wrap']}>
                  <dt className={style['sign-form-a__information-title']} tabIndex={0}>
                    {t('Contracting partner')}
                  </dt>
                  <dd className={style['sign-form-a__information-value']}>{formAUserInfo.data?.contractingPartner}</dd>
                </div>
              )}
            </div>

            <div className={style['sign-form-a__wrap']}>
              {formAUserInfo.data?.category && (
                <>
                  <dt className={style['sign-form-a__information-title']} tabIndex={0}>
                    {t('Category (where appropriate)')}
                  </dt>
                  <dd className={style['sign-form-a__information-value']}>{formAUserInfo.data?.category}</dd>
                </>
              )}
              <dd className={style['sign-form-a__information-value']}>
                {t(
                  'In accordance with Article 4 of the Anti-Money Laundering Act, the contracting partner hereby declares that the person(s) listed below is/are the beneficial owner(s) of the assets deposited under the above relationship. If the contracting partner is the beneficial owner of the assets, the contracting partner’s details must be set out below:'
                )}
              </dd>
            </div>

            <div className={style['sign-form-a__wrap']}>
              <dt className={style['sign-form-a__information-title']} tabIndex={0}>
                {t('First name(s), last name(s) / Entity:')}
              </dt>
              <dd className={style['sign-form-a__information-value']}>{formAUserInfo.data?.fullName}</dd>
            </div>

            <div className={style['sign-form-a__wrap']}>
              <dt className={style['sign-form-a__information-title']} tabIndex={0}>
                {t('First name(English), Last name (English) / Entity:')}
              </dt>
              <dd className={style['sign-form-a__information-value']}>{`${
                formAUserInfo.data?.fullNameEnglish || '--'
              }`}</dd>
            </div>

            <div className={style['sign-form-a__information-group']}>
              <div className={style['sign-form-a__wrap']}>
                <dt className={style['sign-form-a__information-title']} tabIndex={0}>
                  {t('Date of birth')}
                </dt>
                <dd className={style['sign-form-a__information-value']}>
                  {formAUserInfo.data?.dateOfBirth?.replaceAll('-', ' / ')}
                </dd>
              </div>

              <div className={style['sign-form-a__wrap']}>
                <dt className={style['sign-form-a__information-title']} tabIndex={0}>
                  {t('Nationality')}
                </dt>
                <dd className={style['sign-form-a__information-value']}>{formAUserInfo.data?.nationality}</dd>
              </div>
            </div>

            <div className={style['sign-form-a__wrap']}>
              <dt className={style['sign-form-a__information-title']} tabIndex={0}>
                {t('Actual address of domicile / Registered office (incl. country):')}
              </dt>
              <dd className={style['sign-form-a__information-value']}>{formAUserInfo.data?.address}</dd>
              <dd className={style['sign-form-a__information-value']}>
                {t('The contracting partner hereby undertakes to automatically inform the bank of any changes.')}
              </dd>
            </div>
          </dl>

          <Divider />

          <dl className={style['sign-form-a__information']}>
            <div className={style['sign-form-a__information-group']}>
              <div className={style['sign-form-a__wrap']}>
                <dt className={style['sign-form-a__information-title']} tabIndex={0}>
                  {t('Date')}
                </dt>
                <dd className={style['sign-form-a__information-value']}>{`${day} ${month} ${year}`}</dd>
              </div>

              {props.children}
            </div>

            <p className={style['sign-form-a__warning']} id='sign-form-a-warning'>
              {t(
                'It is a criminal offense to deliberately provide false information on this form (Article 251 of the Swiss Criminal Code, document forgery).'
              )}
            </p>
          </dl>
        </>
      )}
    </section>
  );
};
