import { useGetAllContactsCryptoContactCrypto } from '@ping/api';
import { generateBase64CoreIcon } from '@corepass/core-blockies';
import AddressIcon from '@ping/assets/Icon/address.svg';
import { t } from '@ping/helpers';
import { CoinIcon, SearchField } from '@ping/uikit';
import { Select } from '@ping/uikit/SelectA11Y';
import { clsx } from 'clsx';
import { useEffect, useState } from 'react';
import { userCoreIDSelector, useUserInformationStore } from '@ping/stores/userInformation.store';
import { truncateCoreId } from '@ping/utils';

import style from './style.module.scss';

import type { ContactCryptoResponse } from '@ping/api';
import { type PopoverPlacement } from '@ping/uikit/PrimitivePopover/PrimitivePopoverRoot';

interface IAddressSelectProps extends ICustomizable {
  assetId?: string;
  selectedKey?: string;
  label?: string;
  isDisabled?: boolean;
  magnet?: IMagnet;
  boundaryRef?: React.MutableRefObject<HTMLElement>;
  onChange?(address: string): void;
  onOpenChange?: (isOpen: boolean) => void;
  onPanelPlacementChange?: (placement: PopoverPlacement) => void;
}

type ContactCryptoGroup = ContactCryptoResponse & { group?: string };
const CORE_CURRENCIES = ['xcb', 'ctn'];

export const AddressSelect = (props: IAddressSelectProps) => {
  const [items, setItems] = useState<ContactCryptoGroup[]>([]);
  const userCoreID = useUserInformationStore(userCoreIDSelector);

  const { data: contacts, isFetching: isContactsFetching } = useGetAllContactsCryptoContactCrypto({
    query: {
      enabled: Boolean(props.assetId),
      select: contacts => {
        const resultContact: ContactCryptoGroup[] = contacts
          .filter(contact => contact.assetId.toLowerCase() === props.assetId.toLowerCase())
          .map(contact => ({ ...contact, group: t('MY CONTACTS') }));
        if (CORE_CURRENCIES.includes(props.assetId.toLowerCase())) {
          resultContact.unshift({
            assetId: props.assetId,
            accountName: truncateCoreId(userCoreID),
            id: userCoreID,
            group: t('MY COREPASS WALLET'),
          });
        }
        return resultContact;
      },
    },
  });

  useEffect(() => filterViewItems(''), [isContactsFetching, props.assetId]);

  const filterViewItems = (value: string) => {
    if (isContactsFetching || !contacts) {
      return;
    }

    const text = value.trim().toLowerCase();
    const items = contacts.filter(contact => contact.accountName.toLowerCase().includes(text));

    setItems(items);
  };

  const handleOnSelectionChange = (address: string) => {
    filterViewItems('');
    props.onChange?.(address);
  };

  return (
    <Select
      className={clsx(style['address-select'], props.className)}
      items={items}
      isLoading={isContactsFetching}
      selectedKey={props.selectedKey}
      isDisabled={props.isDisabled}
      magnet={props.magnet}
      label={props.label}
      boundaryRef={props.boundaryRef}
      onSelectionChange={handleOnSelectionChange}
      onOpenChange={isOpen => {
        props.onOpenChange?.(isOpen);
        !isOpen && filterViewItems('');
      }}
      onPanelPlacementChange={props.onPanelPlacementChange}
      buttonContent={<AddressIcon />}
      topElement={
        <>
          <SearchField
            className={style['address-select__search']}
            placeholder={t('Search')}
            label={t('Search through addresses')}
            onChange={filterViewItems}
          />
          {!items.length && !isContactsFetching && (
            <p className={style['address-select__not-found']}>{t('Not Found')}</p>
          )}
        </>
      }
    >
      {contact => (
        <Select.Item
          className={style['address-select__item']}
          key={contact.wallet}
          aria-label={contact.assetId.toUpperCase()}
        >
          {contact.group !== t('MY COREPASS WALLET') ? (
            <>
              <CoinIcon className={style['address-select__coin-icon']} name={contact.assetId.toUpperCase()} />
              <span className={style['address-select__name']}>
                {contact.assetId.toUpperCase()} - {contact.accountName}
              </span>
            </>
          ) : (
            <>
              <img
                className={style['address-select__coin-icon']}
                src={generateBase64CoreIcon(userCoreID, 3)}
                alt='core wallet'
              />

              <span className={style['address-select__name']}>{contact.accountName}</span>
            </>
          )}
        </Select.Item>
      )}
    </Select>
  );
};

export default AddressSelect;
