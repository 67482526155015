import clsx from 'clsx';

import style from './style.module.scss';

interface ICardHeaderProps extends ICustomizable {
  children: React.ReactNode;
}

export const CardHeader = (props: ICardHeaderProps) => {
  return <header className={clsx(style['card-header'], props.className)}>{props.children}</header>;
};
