import clsx from 'clsx';

import style from './style.module.scss';

interface ICardTitleProps extends ICustomizable {
  children: React.ReactNode;
}

export const CardTitle = (props: ICardTitleProps) => {
  return <h2 className={clsx(style['card-title'], props.className)}>{props.children}</h2>;
};
