import { KycCardLayout } from '../KycCard/KycCardLayout';
import ForbiddenIcon from '@ping/assets/Icon/forbidden.svg';
import { t } from '@ping/helpers';
import { KycCardContactUsSection } from '../KycCardContactSection';
import style from './style.module.scss';

export const KycCardBanned = () => {
  return (
    <KycCardLayout headerTitle={t('Access forbidden')} HeaderIcon={<ForbiddenIcon />} className={style['kyc-custom']}>
      <div className={style['kyc-banned']}>
        <div className={style['kyc-banned__left-side']}>
          <h6 className={style['kyc-banned__left-side__title']}>{t('We are sorry!')}</h6>
          <p className={style['kyc-banned__left-side__description']}>
            {t(
              'It seems you are trying to access Ping from a Core ID that has previously been restricted from using our services due to its inability to adhere to international regulations. We sincerely apologize for any inconvenience this may cause.'
            )}
          </p>
        </div>
        <KycCardContactUsSection />
      </div>
    </KycCardLayout>
  );
};
