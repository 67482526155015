import { useState } from 'react';
import { t } from '@ping/helpers';
import { ToggleButton } from '@ping/uikit';

import SendTransaction from './SendTransaction';
import style from './style.module.scss';
import ReceiveTransaction from './ReceiveTransaction';

export enum SEND_RECEIVE_TABS {
  SEND = 'SEND',
  RECEIVE = 'RECEIVE',
}

interface ICryptoTransactionProps {
  action?: SEND_RECEIVE_TABS;
  assetId?: string;
  onCancel?: () => void;
}

const isActionSend = (value: string) => value === SEND_RECEIVE_TABS.SEND;
const isActionReceive = (value: string) => value === SEND_RECEIVE_TABS.RECEIVE;

export const CryptoTransaction = (props: ICryptoTransactionProps) => {
  const [action, setAction] = useState(props.action);

  return (
    <div className={style['crypto-transaction']}>
      <ToggleButton.Root
        className={style['crypto-transaction__toggle']}
        defaultValue={action}
        onChange={(val: SEND_RECEIVE_TABS) => setAction(val)}
      >
        <ToggleButton.Choice value={SEND_RECEIVE_TABS.SEND}>{t('Send')}</ToggleButton.Choice>
        <ToggleButton.Choice value={SEND_RECEIVE_TABS.RECEIVE}>{t('Receive')}</ToggleButton.Choice>
      </ToggleButton.Root>

      <div className={style['crypto-transaction__content']} aria-hidden={!isActionSend(action)}>
        <SendTransaction assetId={props.assetId} onCancel={props.onCancel} />
      </div>

      <div className={style['crypto-transaction__content']} aria-hidden={!isActionReceive(action)}>
        <ReceiveTransaction assetId={props.assetId} />
      </div>
    </div>
  );
};

export default CryptoTransaction;
