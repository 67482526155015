import { isNil } from 'lodash-es';
import { useRef, useState } from 'react';
import { useClientEffect } from './use-client-effect.hook';

interface IScrollToParams extends IntersectionObserverInit {
  /** unique `data-<token>`. need to be set on the target */
  token: string;
}

export const useScrollTo = (params: IScrollToParams) => {
  const targetRef = useRef<HTMLElement>();
  const [isTargetInView, setIsTargetInView] = useState(false);

  useClientEffect(() => {
    if (isNil(params.token)) {
      return;
    }

    targetRef.current = document.querySelector<HTMLElement>(`[${params.token}]`);
    if (isNil(targetRef.current)) {
      return setIsTargetInView(true);
    }

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setIsTargetInView(entry.isIntersecting));
    }, params);

    observer.observe(targetRef.current);

    return () => {
      observer.disconnect();
    };
  }, [params.token]);

  return {
    isTargetInView,
    containerProps: {
      'aria-hidden': isTargetInView,
      'data-scroll-target-in-view': isTargetInView || undefined,
    },
    buttonProps: {
      onClick: () => targetRef.current?.scrollIntoView({ behavior: 'smooth' }),
    },
  };
};
