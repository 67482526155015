import { Store } from '@ping/utils';

interface IEnhanceAssetsNoticeStore {
  canShow: boolean;
}

// --------------------------------------------------------------------------------
// ENHANCE ASSETS NOTICE STORE
// --------------------------------------------------------------------------------
export const useEnhanceAssetsNoticeStore = new Store<IEnhanceAssetsNoticeStore>({ canShow: true })
  .withPersist({ name: 'ENHANCE_ASSETS_NOTICE_STORE' })
  .withDevTools()
  .build();

// --------------------------------------------------------------------------------
// ENHANCE ASSETS NOTICE SELECTORS
// --------------------------------------------------------------------------------
export const canShowEnhanceAssetsNoticeSelector = (state: IEnhanceAssetsNoticeStore) => state.canShow;

// --------------------------------------------------------------------------------
// ENHANCE ASSETS NOTICE API
// --------------------------------------------------------------------------------
export const enhanceAssetsNoticeStore = {
  setEnhanceAssetsNotice: (canShowValue: boolean) => useEnhanceAssetsNoticeStore.setState({ canShow: canShowValue }),
};
