import CircleWarningIcon from '@ping/assets/Icon/circle-warning.svg';
import { t } from '@ping/helpers';
import { Text } from '@ping/uikit';
import Link from 'next/link';

import style from './style.module.scss';

interface ILimitWarningProps {
  usersCurrentLimit: number;
  perTransactionLimit?: number;
  totalMonthlyLimit?: number;
  totalYearlyLimit?: number;
  userSelectedCurrency?: string;
}

export const LimitsWarningMessage = ({
  usersCurrentLimit,
  perTransactionLimit,
  totalMonthlyLimit,
  totalYearlyLimit,
  userSelectedCurrency,
}: ILimitWarningProps) => {
  return (
    <section className={style['limits-warning']}>
      <span className={style['limits-warning__icon']}>
        <CircleWarningIcon />
      </span>

      <div>
        <Text body='semi-bold' className={style['limits-warning__heading']}>
          {usersCurrentLimit === perTransactionLimit && t('Exceeding max transaction limit')}
          {usersCurrentLimit === totalMonthlyLimit && t('Exceeding monthly withdraw limit')}
          {usersCurrentLimit === totalYearlyLimit && t('Exceeding yearly withdraw limit')}
        </Text>
        <Text body='regular'>
          {usersCurrentLimit === perTransactionLimit &&
            t(
              `Please, note that the transaction you are about to make exceeds the ${perTransactionLimit} ${userSelectedCurrency} per transaction maximum limit.`
            )}
          {usersCurrentLimit === totalMonthlyLimit &&
            t(
              `Please, note that the transaction you are about to make exceeds the ${totalMonthlyLimit} ${userSelectedCurrency} calendar month withdraw limit`
            )}
          {usersCurrentLimit === totalYearlyLimit &&
            t(
              `Please, note that the transaction you are about to make exceeds the ${totalYearlyLimit} ${userSelectedCurrency} calendar year limit.`
            )}
        </Text>
        <div className={style['limits-warning__link']}>
          <Link href='/user/tiers'>{t('Learn more about tiers')}</Link>
        </div>
      </div>
    </section>
  );
};
