import { useEffect } from 'react';

/**
 * It executes a callback function after a specified delay.
 * @param callback - The callback function to be executed.
 * @param ms - The delay in milliseconds before executing the callback. If not specified or less than 0, the callback won't be executed.
 * @param args - Additional arguments to be passed to the callback function.
 */
export const useTimeoutEffect = <TArgs extends any[]>(
  callback: (...args: TArgs) => void,
  ms?: number,
  ...args: TArgs
) => {
  useEffect(() => {
    if (isNaN(ms) || ms < 0) {
      return;
    }

    const timeoutId = setTimeout(callback, ms, args);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [callback, ms, ...args]);
};
