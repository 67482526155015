import { QRCodeSVG } from 'qrcode.react';
import { isEmpty } from 'rambdax';
import { useEffect, useReducer, useRef, useState } from 'react';

import { AssetResponse, useGetAllAsset, useGetPortfolio, useWithdrawalWallet } from '@ping/api';
import ArrowLeft from '@ping/assets/Icon/arrowLeft.svg';
import { ContactCrypto, TooltipWithdrawalLimitsInfo, WithdrawTierUpgradingStepModal } from '@ping/components';
import { t } from '@ping/helpers';
import * as gtm from '@ping/helpers/gtm.helper';
import { useConversionAssetToFiat, useMobile, useToggle, useWithdrawalLimits } from '@ping/hooks';
import {
  transferOnProcessingModalRemoteStateSelector,
  useModalStore,
  withdrawalTierUpgradeModalRemoteStateSelector,
} from '@ping/stores/modal.store';
import { AssetComboBox, Button, Checkbox, Divider, Popup, ProgressBar, Text } from '@ping/uikit';
import { format } from '@ping/utils';
import { useNotificationWS } from '@ping/websockets';
import { NotificationEvents, WithdrawNotificationStatus } from '@ping/websockets/useNotificationWS';

import AddressComboBox from '../AddressComboBox';
import MobileConfirmation from '../MobileConfirmation';

import { LimitsWarningMessage } from './LimitsWarningMessage';
import { validateAmount } from './validations.helper';
import style from './style.module.scss';

const ONE_DAY = 1 * 60 * 60 * 24 * 1000;

interface ISendTransactionProps {
  assetId?: string;
  onCancel(): void;
}

interface ISendTransactionState {
  assetId: string;
  assetAmount: number;
  address: string;
  balance: number;
}

interface IAllAssetsWithdrawInfo {
  withdrawFee: number;
  minWithdraw: number;
}

enum ESteps {
  SEND_TRANSACTION = 'SEND_TRANSACTION',
  SAVE_ADDRESS = 'SAVE_ADDRESS',
  WITHDRAWAL_CONFIRMATION = 'WITHDRAWAL_CONFIRMATION',
}

const PER_TRANSACTION_ERROR_CODE = 70101;
const PER_MONTH_ERROR_CODE = 70103;
const PER_YEAR_ERROR_CODE = 70105;
const UPGRADE_TIER_ERROR_CODES = [70100, 70102, 70104];
const UPGRADE_TIER_SUB_ERROR_CODES = [PER_TRANSACTION_ERROR_CODE, PER_MONTH_ERROR_CODE, PER_YEAR_ERROR_CODE];

const SendTransaction = (props: ISendTransactionProps) => {
  const isMobile = useMobile();
  const [confirmationLink, setConfirmationLink] = useState('');
  const [isVaspConfirmed, toggleVaspConfirmation] = useToggle(false);
  const [amountError, setAmountError] = useState<string | null>(null);
  const withdrawLimits = useWithdrawalLimits();

  const { data: portfolio } = useGetPortfolio();

  const { data: withdrawInfo } = useGetAllAsset({
    query: {
      staleTime: ONE_DAY,
      select: ({ data: allAssets }) => {
        return allAssets.reduce(
          (map, asset) => map.set(asset.id, { withdrawFee: asset.withdrawal_fee, minWithdraw: asset.min_withdraw }),
          new Map<string, IAllAssetsWithdrawInfo>()
        );
      },
    },
  });

  const { mutate: sendTransaction } = useWithdrawalWallet();
  const [saveStep, setSaveStep] = useState<ESteps>(ESteps.SEND_TRANSACTION);
  const [withdrawConfirmationStep, setWithdrawConfirmationStep] = useState<ESteps>(ESteps.SEND_TRANSACTION);
  const [usersCurrentLimit, setUsersCurrentLimit] = useState(0);
  const withdrawalTierUpgradeModalRemoteState = useModalStore(withdrawalTierUpgradeModalRemoteStateSelector);
  const transferOnProcessingModalState = useModalStore(transferOnProcessingModalRemoteStateSelector);
  const { notificationData, notificationCallBack } = useNotificationWS();
  const targetTierToUpgrade = useRef<number>();
  const exceededLimitAmount = useRef<string>();
  const [isTooltipAddressVisible, toggleTooltipAddressVisible] = useToggle(false);

  notificationCallBack?.(NotificationEvents.OnWithdrawalStatusChanged);

  useEffect(() => {
    if (notificationData?.status === WithdrawNotificationStatus.SignatureVerified) {
      handleCloseWithdrawConfirmationStep();
      transferOnProcessingModalState.open();
    }
  }, [notificationData]);

  const [state, setState] = useReducer(
    (prev: ISendTransactionState, next: Partial<ISendTransactionState>) => ({
      ...prev,
      ...next,
    }),
    {
      assetId: props.assetId,
      assetAmount: 0,
      balance: 0,
      address: '',
    }
  );

  const currencyConversionRate = useConversionAssetToFiat(state.assetId);
  const minWithdrawAmount = withdrawInfo?.get(state.assetId)?.minWithdraw;
  const enteredAmountValidation = validateAmount(state.assetAmount, minWithdrawAmount, state.balance);

  const limitsListArray = Object.entries(withdrawLimits.limitsList)
    .filter(([key]) => key !== 'totalCurrentMonthWithdrawalAmount')
    .map(([_, value]) => value);

  const convertedTotalAmount = state.assetAmount * currencyConversionRate.rate;
  const amountIsHigherThanLimits = limitsListArray.filter(el => el < convertedTotalAmount);
  const amountIsHigherInt64 = convertedTotalAmount >= Number.MAX_SAFE_INTEGER;

  useEffect(() => {
    setUsersCurrentLimit(
      limitsListArray.filter(el => el < convertedTotalAmount).reduce((a, b) => Math.max(a, b), -Infinity)
    );
  }, [withdrawLimits.limitsList, convertedTotalAmount]);

  const handleChange = newState => {
    const selectedAssetBalance = portfolio?.portfolioItems?.find(asset => asset.assetId === newState.assetId)?.amount;
    const validationError = validateAmount(newState.assetAmount, minWithdrawAmount, selectedAssetBalance) as string;
    newState.balance = selectedAssetBalance;
    setAmountError(validationError);
    setState(newState);
  };

  const handleOnSaveAddress = () => {
    setSaveStep(ESteps.SAVE_ADDRESS);
  };

  const handleOnSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    sendTransaction(
      { data: { assetId: state.assetId, address: state.address, amount: state.assetAmount } },
      {
        onSuccess: (data: string) => {
          gtm.withdrawalEvent(state.assetId);
          setConfirmationLink(data);
          setWithdrawConfirmationStep(ESteps.WITHDRAWAL_CONFIRMATION);
        },
        onError: (error: any) => {
          const errorData = error?.response?.data;
          if (UPGRADE_TIER_ERROR_CODES.includes(errorData?.errorCode)) {
            const limitExceededData = errorData?.errors?.find(errorItem =>
              UPGRADE_TIER_SUB_ERROR_CODES.includes(+errorItem.key)
            );
            if (limitExceededData) {
              const targetTier = limitExceededData?.data?.SuggestedUserComplianceReviewRequestType?.split('To')[1];
              targetTierToUpgrade.current = targetTier.replace('Tier', '');
              exceededLimitAmount.current = limitExceededData?.data?.WithdrawalLimitAmount;
              withdrawalTierUpgradeModalRemoteState.open();
            }
          }
        },
      }
    );
  };

  const handleCloseSaveStep = () => {
    setSaveStep(ESteps.SEND_TRANSACTION);
  };

  const handleCloseWithdrawConfirmationStep = () => {
    setWithdrawConfirmationStep(ESteps.SEND_TRANSACTION);
  };

  const handleSaveAddress = () => {
    handleCloseSaveStep();
  };

  if (saveStep === ESteps.SAVE_ADDRESS) {
    const TitleComponent = () => (
      <>
        <ArrowLeft className={style['send-transaction__save-popup__back-arrow']} onClick={handleCloseSaveStep} />
        <span>{t('Save address')}</span>
      </>
    );
    return (
      <Popup title={<TitleComponent />} isOpen onClose={handleCloseSaveStep}>
        <ContactCrypto
          finishCallback={handleSaveAddress}
          defaultValues={{
            address: state.address,
            assetId: state.assetId,
          }}
        />
      </Popup>
    );
  }

  if (withdrawConfirmationStep === ESteps.WITHDRAWAL_CONFIRMATION) {
    return (
      <>
        {!isMobile && (
          <Popup isOpen onClose={handleCloseWithdrawConfirmationStep} fullscreen>
            <div className={style['send-transaction__withdrawal-confirmation']}>
              <div className={style['send-transaction__withdrawal-confirmation__block']}>
                <div className={style['send-transaction__withdrawal-confirmation__qr-wrapper']}>
                  <QRCodeSVG size={304} value={decodeURIComponent(confirmationLink)} level='L' />
                </div>

                <div className={style['send-transaction__withdrawal-confirmation__info']}>
                  <Text heading='3'>{t('Sign transaction')}</Text>
                  <Text body='regular'>{t('Scan QR code with your CorePass app to verify this transaction.')}</Text>
                </div>
              </div>
            </div>
          </Popup>
        )}

        {isMobile && (
          <Popup isOpen fullscreen>
            <MobileConfirmation onClose={handleCloseWithdrawConfirmationStep} confirmationLink={confirmationLink} />
          </Popup>
        )}
      </>
    );
  }

  const handleShowAsset = (asset: AssetResponse): boolean => {
    return Boolean(portfolio.portfolioItems.find(item => item.assetId === asset.id && asset.can_withdraw));
  };

  const handleMouseEnter = () => {
    if (!state.assetId && Boolean(portfolio.portfolioItems.length)) {
      setAmountError(t('Select your token and then enter your value'));
    }
  };

  return (
    <form className={style['send-transaction']} onSubmit={handleOnSubmit}>
      <WithdrawTierUpgradingStepModal
        limit={exceededLimitAmount.current}
        currentUserTier={withdrawLimits.withdrawalLimit?.complianceTier}
        targetTier={targetTierToUpgrade.current}
      />

      <ProgressBar
        title={t('Monthly limit')}
        currentProgressValue={withdrawLimits.limitsList.totalCurrentMonthWithdrawalAmount}
        maxProgressValue={withdrawLimits.limitsList.totalMonthlyLimit}
        currency={withdrawLimits.selectedCurrency}
        isLoading={withdrawLimits.withdrawalLimitLoading}
        tooltipContent={
          <TooltipWithdrawalLimitsInfo
            perTransactionLimit={withdrawLimits.limitsList.perTransactionLimit}
            totalMonthlyLimit={withdrawLimits.limitsList.totalMonthlyLimit}
            totalYearlyLimit={withdrawLimits.limitsList.totalYearlyLimit}
            userTier={withdrawLimits.withdrawalLimit?.complianceTier}
            limitCurrency={withdrawLimits.selectedCurrency}
          />
        }
      />

      <div onMouseEnter={handleMouseEnter}>
        <AssetComboBox
          className={style['send-transaction__asset-combo-box']}
          defaultAssetId={state.assetId}
          defaultAmount={state.assetAmount}
          onChange={handleChange}
          filterFn={handleShowAsset}
          error={amountError || enteredAmountValidation}
        />
      </div>

      {amountIsHigherThanLimits.length != 0 && !amountIsHigherInt64 && (
        <LimitsWarningMessage
          usersCurrentLimit={usersCurrentLimit}
          perTransactionLimit={withdrawLimits.limitsList.perTransactionLimit}
          totalMonthlyLimit={withdrawLimits.limitsList.totalMonthlyLimit}
          totalYearlyLimit={withdrawLimits.limitsList.totalYearlyLimit}
          userSelectedCurrency={withdrawLimits.selectedCurrency}
        />
      )}

      <div
        className={style['send-transaction__address']}
        onMouseEnter={() => toggleTooltipAddressVisible(true)}
        onMouseLeave={() => toggleTooltipAddressVisible(false)}
      >
        <AddressComboBox
          assetId={state.assetId}
          defaultAddress={state.address}
          isDisabled={!state.assetId || !state.assetAmount}
          onSave={handleOnSaveAddress}
          onChange={address => setState({ address })}
        />
        {isTooltipAddressVisible && state.address && (
          <div className={style['send-transaction__address-tooltip']}>{state.address}</div>
        )}
      </div>

      {state.assetId && (
        <>
          <Divider />
          <div className={style['send-transaction__info']}>
            <div className={style['send-transaction__info_label']}>
              <span className={style['send-transaction__info_fee-text']}>{t('Transaction fee')}</span>
              <span className={style['send-transaction__info_fee-text']}>
                {`${withdrawInfo?.get(state.assetId)?.withdrawFee} ${state.assetId.toUpperCase()} (
          ${currencyConversionRate.rate * withdrawInfo?.get(state.assetId)?.withdrawFee} ${
                  currencyConversionRate.selectedCurrency
                })`}
              </span>
            </div>

            {Boolean(state.assetAmount) && (
              <div className={style['send-transaction__info_label']}>
                <span className={style['send-transaction__info_label-amount']}>{t('Amount to receive')}</span>
                <span className={style['send-transaction__info_label-amount']}>
                  {`${format.crypto(
                    state.assetAmount - withdrawInfo?.get(state.assetId)?.withdrawFee,
                    state.assetId.toUpperCase()
                  )}`}
                </span>
              </div>
            )}
          </div>
        </>
      )}

      <Checkbox
        className={style['send-transaction__vasp']}
        name='vasp-confirmation'
        text={t(
          'I declare that the funds I am transferring are not going to a Virtual Asset Service Provider (VASP), and I am the exclusive owner of this wallet.'
        )}
        checked={isVaspConfirmed}
        onChange={toggleVaspConfirmation}
      />

      <footer className={style['send-transaction__actions']}>
        <Button
          className={style['send-transaction__button']}
          type='secondary'
          design='general'
          size='large'
          uppercase
          htmlType='button'
          onClick={props.onCancel}
        >
          {t('CANCEL')}
        </Button>

        <Button
          className={style['send-transaction__button']}
          type='primary'
          design='general'
          size='large'
          uppercase
          disabled={
            !state.assetId ||
            !state.assetAmount ||
            !state.address ||
            state.assetAmount > state.balance ||
            !isVaspConfirmed ||
            !isEmpty(enteredAmountValidation)
          }
          htmlType='submit'
        >
          {t('Send')}
        </Button>
      </footer>
    </form>
  );
};

export default SendTransaction;
