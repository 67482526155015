import { useSwapTrade, useGetWithdrawalLimit } from '@ping/api';
import { useSelectedCurrency } from '@ping/hooks';
import { useState } from 'react';
import { DEFAULT_CURRENCY } from '@ping/configs';

export const useWithdrawalLimits = () => {
  const { data: withdrawalLimit, isLoading: withdrawalLimitLoading } = useGetWithdrawalLimit();
  const [limitsList, setLimitsList] = useState({
    perTransactionLimit: 0,
    totalMonthlyLimit: 0,
    totalYearlyLimit: 0,
    totalCurrentMonthWithdrawalAmount: 0,
  });
  const selectedCurrency = useSelectedCurrency();

  useSwapTrade(
    {
      FromAssetId: withdrawalLimit?.limitCurrency.toLowerCase(),
      ToAssetId: selectedCurrency.toLowerCase(),
      Amount: 1,
      DefaultCurrency: DEFAULT_CURRENCY.toLowerCase(),
    },
    {
      query: {
        enabled: !withdrawalLimitLoading,
        onSuccess: response => {
          setLimitsList({
            perTransactionLimit: withdrawalLimit?.perTransactionLimit * response.rate,
            totalMonthlyLimit: withdrawalLimit?.totalMonthlyLimit * response.rate,
            totalYearlyLimit: withdrawalLimit?.totalYearlyLimit * response.rate,
            totalCurrentMonthWithdrawalAmount: withdrawalLimit?.totalCurrentMonthWithdrawalAmount * response.rate,
          });
        },
      },
    }
  );

  return { withdrawalLimit, withdrawalLimitLoading, limitsList, selectedCurrency };
};
