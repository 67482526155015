import clsx from 'clsx';
import style from './style.module.scss';

type TKycCardLayoutProps = {
  HeaderIcon: React.ReactElement;
  headerTitle: React.ReactNode;
  HeaderContainerChild?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
};

export const KycCardLayout = (props: TKycCardLayoutProps) => {
  const { children, HeaderIcon, headerTitle, HeaderContainerChild, className = '' } = props;
  return (
    <div className={clsx(style['kyc-card'], className)}>
      <div className={style['kyc-card__header']}>
        <div className={style['kyc-card__header-container']}>
          <div className={style['kyc-card__header-icon']}>{HeaderIcon}</div>
          <h2 className={style['kyc-card__header-title']}>{headerTitle}</h2>
          {HeaderContainerChild}
        </div>
      </div>
      <div className={style['kyc-card-content']}>{children}</div>
    </div>
  );
};
