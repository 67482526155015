import CoinsIcon from '@ping/assets/Icon/coins.svg';
import EmptyTableView from '@ping/components/EmptyTableView';
import { t } from '@ping/helpers';

import style from './style.module.scss';

const NoAssetView = () => {
  return (
    <EmptyTableView
      title={t('No assets yet')}
      caption={t('You assets will be listed here after deposit')}
      icon={<CoinsIcon className={style['no-asset-view__icon']} />}
    />
  );
};

export default NoAssetView;
