import { t } from '@ping/helpers/';
import style from './style.module.scss';
import { Text } from '@ping/uikit';
import { ECurrency } from '@ping/enums';
import Link from 'next/link';

//todo remove mock
const USER_TIER_MOCK = 'Tier 1';
const LIMIT_CURRENCY_MOCK = ECurrency.CHF;

interface ITooltipWithdrawalLimitsInfoProps {
  userTier?: string;
  perTransactionLimit?: number;
  totalMonthlyLimit?: number;
  totalYearlyLimit?: number;
  limitCurrency?: string;
}

export const TooltipWithdrawalLimitsInfo = ({
  userTier,
  perTransactionLimit,
  totalMonthlyLimit,
  limitCurrency,
  totalYearlyLimit,
}: ITooltipWithdrawalLimitsInfoProps) => {
  const displayLimit = limit => {
    if (limit >= Number.MAX_SAFE_INTEGER) {
      return t('Unlimited');
    }
    return limit;
  };
  return (
    <div className={style['tooltip-withdrawal-limits-info']}>
      <Text className={style['tooltip-withdrawal-limits-info__heading']}>{userTier || USER_TIER_MOCK}</Text>
      <Text className={style['tooltip-withdrawal-limits-info__info']}>
        {t('Your current tier allows you to withdraw: ')}
        <br />
        {t('%s %s / Transaction', displayLimit(perTransactionLimit), limitCurrency || LIMIT_CURRENCY_MOCK)}
        <br />
        {t('%s %s / Month', displayLimit(totalMonthlyLimit), limitCurrency || LIMIT_CURRENCY_MOCK)}
        <br />
        {t('%s %s / Year', displayLimit(totalYearlyLimit), limitCurrency || LIMIT_CURRENCY_MOCK)}
      </Text>
      <div className={style['tooltip-withdrawal-limits-info__link']}>
        <Link href='/user/tiers'>{t('Learn more about tiers')}</Link>
      </div>
    </div>
  );
};
