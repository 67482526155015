import { useRef } from 'react';
import { clsx } from 'clsx';
import DoneIcon from '@ping/assets/Icon/done.svg';
import FailedIcon from '@ping/assets/Icon/failed.svg';
import { t } from '@ping/helpers';
import { ButtonA11Y, Modal, Spinner } from '@ping/uikit';

import { KycWatchTutorialModalTrigger } from '../KycWatchTutorialModalTrigger';
import style from './style.module.scss';

import type { IModalTriggerProps } from '@ping/uikit';

interface IKycCheckStatusModalTriggerProps extends IModalTriggerProps {
  onOpen: () => void;
  kycRequestMutation: () => void;
  errorMessageData: object;
  isRequestProcessing: boolean;
}

export const KycCheckStatusModalTrigger = (props: IKycCheckStatusModalTriggerProps) => {
  const { current: userRequiredInformation } = useRef({
    FullName: t('Full name'),
    DOB: t('Date of birth'),
    AdditionalImage: t('Additional image'),
    DocumentNumber: t('ID document number'),
    ExpiryDate: t('Expiry date'),
    DocumentImage: t('Document image'),
    FaceImage: t('Face image'),
    Country: t('Country'),
    AML_Check: t('AML check'),
    AML_Detail: t('AML detail'),
    Email: t('Email address'),
    Address_Country: t('Address country'),
    Address_City: t('Address city'),
    Address_DocumentImage: t('Address document image'),
    Address_ExpiryDate: t('Address expiry date'),
    Address_FULLNAME: t('Address full name'),
    Address_State: t('Address state'),
    Address_Street: t('Address street'),
    Address_Street2: t('Address street 2'),
    Address_ZipCode: t('Address ZIP code'),
  });

  return (
    <Modal.Root onOpenChange={isOpen => isOpen && props.onOpen()}>
      <Modal.Trigger {...props} className={clsx(style['kyc-check-status-trigger'], props.className)}>
        {t('Check status')}
      </Modal.Trigger>

      <Modal.Portal className={style['kyc-check-status-modal']}>
        <Modal.Header>
          <Modal.Title>{t('Required information')}</Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>

        <Modal.Content>
          {props.isRequestProcessing && (
            <p className={style['kyc-check-status-modal__text']}>
              {t('Your request is still being processed, please wait.')}
            </p>
          )}

          {!props.isRequestProcessing && (
            <>
              <p className={style['kyc-check-status-modal__text']}>
                {t('Please, fill out missing details in your CorePass ID and do another status check to continue.')}
              </p>

              <Spinner
                isLoading={props.isLoading}
                size={50}
                spinnerType='clipLoader'
                className={style['kyc-check-status-modal__spinner']}
              />
              <ul
                className={clsx(style['kyc-check-status-modal__list'], {
                  [style['kyc-check-status-modal__list-visibility']]: props.isLoading,
                })}
              >
                {Object.keys(props.errorMessageData || {}).map(item => (
                  <li className={style['kyc-check-status-modal__item']} key={item}>
                    {props.errorMessageData[item] ? (
                      <DoneIcon className={style['kyc-check-status-modal__icon']} data-type='success' />
                    ) : (
                      <FailedIcon className={style['kyc-check-status-modal__icon']} data-type='error' />
                    )}
                    {userRequiredInformation[item]}
                  </li>
                ))}
              </ul>
            </>
          )}
        </Modal.Content>

        <Modal.Actions>
          <KycWatchTutorialModalTrigger />
          <ButtonA11Y
            isDisabled={props.isDisabled}
            isLoading={props.isLoading}
            className={style['kyc-check-status-trigger']}
            onPress={props.kycRequestMutation}
          >
            {t('Refresh')}
          </ButtonA11Y>
        </Modal.Actions>
      </Modal.Portal>
    </Modal.Root>
  );
};
