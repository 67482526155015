import CheckIcon from '@ping/assets/Icon/check.svg';
import CloseIcon from '@ping/assets/Icon/close.svg';
import { t } from '@ping/helpers';
import { Button } from '@ping/uikit';

import style from './style.module.scss';

interface IMobileConfirmationProps {
  onClose: () => void;
  confirmationLink: string;
}

const MobileConfirmation = (props: IMobileConfirmationProps) => (
  <section className={style['mobile-confirmation']}>
    <header className={style['mobile-confirmation__header']}>
      <img className={style['mobile-confirmation__logo']} src='/img/logo-dark.svg' alt='logo' />
      <button className={style['mobile-confirmation__close-button']} type='button' onClick={props.onClose}>
        <CloseIcon className={style['mobile-confirmation__close-icon']} />
      </button>
    </header>

    <hr className={style['mobile-confirmation__divider']} />

    <h1 className={style['mobile-confirmation__title']}>
      {t('Sign your transaction in CorePass by clicking the button below')}
    </h1>

    <p className={style['mobile-confirmation__description']}>
      {t('To be the most trusted place to buy and sell cryptocurrency and other assets, we utilize ')}
      <a href='https://corepass.net/'>{t('CorePass - decentralized digital identity')}</a>
      {t(
        ' to protect your personal data, secure communications and data transactions, and to comply with the EU’s General Data Protection Regulation (GDPR).'
      )}
    </p>

    <Button className={style['mobile-confirmation__cta']} type='primary' design='general' size='extra-large' uppercase>
      <a href={props.confirmationLink}>{t('Open CorePass')}</a>
    </Button>

    <footer className={style['mobile-confirmation__footer']}>
      <p>
        {t('Powered by ')}
        <strong>{t('CorePass')}</strong>
      </p>
      <hr className={style['mobile-confirmation__vertical-divider']} />
      <div className={style['mobile-confirmation__gdrp']}>
        <CheckIcon className={style['mobile-confirmation__check-icon']} />
        <p>
          <strong>{t('GDPR')}</strong>
          {t(' ready')}
        </p>
      </div>
    </footer>
  </section>
);

export default MobileConfirmation;
