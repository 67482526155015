import { Spinner } from '@ping/uikit';

import style from './style.module.scss';

export function PageSpinner() {
  return (
    <div className={style['page-spinner']}>
      <Spinner spinnerType='clipLoader' color='#4E60FF' />
    </div>
  );
}
