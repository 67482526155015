import { useState } from 'react';
import { Text, Modal, Checkbox, ButtonA11Y } from '@ping/uikit';
import { t } from '@ping/helpers';
import { ECurrency } from '@ping/enums';
import { modalKeys } from '@ping/constants/modal-keys.constant';
import { useRemoteModal } from '@ping/hooks';
import Link from 'next/link';

import style from './style.module.scss';

interface IWithdrawTierUpgradingStepModalProps {
  // onCloseCallback(): void;
  limit?: string;
  currentUserTier?: string;
  targetTier?: number;
}

export const WithdrawTierUpgradingStepModal = ({
  limit,
  currentUserTier,
  targetTier,
}: IWithdrawTierUpgradingStepModalProps) => {
  const modal = useRemoteModal(modalKeys.WITHDRAWAL_TIER_UPGRADE);
  const [isChecked, setIsChecked] = useState(false);
  const nextTier = `${currentUserTier?.slice(0, -1)}${targetTier}`;

  return (
    <>
      <Modal.Root {...modal.props}>
        <Modal.Portal className={style['withdraw-tier-upgrade']}>
          <Modal.Header>
            <Modal.Title className={style['withdraw-tier-upgrade__title']}>
              {t('You are exceeding the transaction limit')}
            </Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Content className={style['withdraw-tier-upgrade__content']}>
            <Text body='regular'>
              {t(
                `Please, note that the transaction you are about to make exceeds the  %s %s transaction limit.`,
                limit,
                ECurrency.CHF
              )}
            </Text>
            <Text body='regular'>
              {t(
                'In order to proceed you will have to fill out an additional form. Once submitted, this transaction will be put on hold until the review process is completed.'
              )}
            </Text>
            <Text body='regular'>
              {t(
                'Keep in mind that you won’t be able to make new transactions until this process is completed. If the review is successfully completed, you’ll be upgraded to %s.',
                nextTier
              )}
              <Link href='/user/tiers' className={style['withdraw-tier-upgrade__link']}>
                {t(' Learn more about tiers')}
              </Link>
            </Text>

            <Checkbox
              text={t('I have read and agree with the statement above.')}
              checked={isChecked}
              onChange={() => setIsChecked(!isChecked)}
              name='upgrade_tier'
            />
          </Modal.Content>
          <Modal.Actions className={style['withdraw-tier-upgrade__actions']}>
            <ButtonA11Y
              className={style['withdraw-tier-upgrade__button']}
              onPress={() => {
                modal.state.close();
              }}
            >
              {t('CANCEL')}
            </ButtonA11Y>

            <div className={style['withdraw-tier-upgrade__button-link']} aria-disabled={!isChecked}>
              <Link href={`/user/tiers?upgradeTo=${targetTier}`}>{t('Continue')}</Link>
            </div>
          </Modal.Actions>
        </Modal.Portal>
      </Modal.Root>
    </>
  );
};
