import { submitReviewRequestCompliance, useGetNextStepCompliance } from '@ping/api';
import LockIcon from '@ping/assets/Icon/lock.svg';
import { t } from '@ping/helpers';
import { pendingForReviewModalRemoteStateSelector, useModalStore } from '@ping/stores/modal.store';
import { Card, Divider } from '@ping/uikit';
import { FormBWizard } from '@user/components';

import style from './style.module.scss';

export const FormBCard = () => {
  const nextStep = useGetNextStepCompliance();
  const pendingForReviewModal = useModalStore(pendingForReviewModalRemoteStateSelector);

  const handleOnSubmit = () => {
    submitReviewRequestCompliance().then(pendingForReviewModal.open);
  };

  return (
    <Card.Root className={style['form-b-card']}>
      <Card.Header>
        <Card.LeadingIcon>
          <LockIcon />
        </Card.LeadingIcon>
        <Card.Title>{t('Unlock Trading')}</Card.Title>
      </Card.Header>

      <Card.Divider />

      <Card.Body className={style['form-b-card__content']}>
        <div className={style['form-b-card__details']}>
          <h4 className={style['form-b-card__subtitle']}>{t('Fill out the form')}</h4>
          <p className={style['form-b-card__description']}>
            {t('We need a few more details from you. Your account will be temporarily under a review process after.')}
          </p>
        </div>

        <Divider className={style['form-b-card__divider']} />

        <div className={style['form-b-card__wizard']}>
          {!nextStep.isLoading && (
            <FormBWizard.Root defaultStep={nextStep.data} onSubmit={handleOnSubmit}>
              <FormBWizard.Content />
              <FormBWizard.Actions>
                <FormBWizard.BackButton />
                <FormBWizard.NextButton />
                <FormBWizard.SubmitButton />
              </FormBWizard.Actions>
            </FormBWizard.Root>
          )}
        </div>
      </Card.Body>
    </Card.Root>
  );
};
