import clsx from 'clsx';

import style from './style.module.scss';

interface ICardBodyProps extends ICustomizable {
  children: React.ReactNode;
}

export const CardBody = (props: ICardBodyProps) => {
  const { children, className, ...rest } = props;

  return (
    <div className={clsx(style['card-body'], className)} {...rest}>
      {children}
    </div>
  );
};
