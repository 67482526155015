// TODO: why have we declared this util?
/**
 * @function sortObject
 * @description This function sorts the keys of the given object, assigns each value to its corresponding keys, and returns a sorted object.
 * @param {object} unsortedObject Is an unsorted object that we want to sort based on its keys.
 * @param {boolean} reverse Is a boolean that determines the direction of sorting, either in ascending or descending order.
 * @returns An object with its keys sorted in alphabetical order.
 */
export const sortObject = (unsortedObject: boolean, reverse = false) => {
  const sortedObjectKeys = Object.keys(unsortedObject).sort();
  const reversedObjectKeys = sortedObjectKeys.reverse();
  const objectKeysSortDirection = reverse ? reversedObjectKeys : sortedObjectKeys;

  return objectKeysSortDirection.reduce((objEntries, key) => {
    objEntries[key] = unsortedObject[key];

    return objEntries;
  }, {});
};
