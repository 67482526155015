import clsx from 'clsx';

import style from './style.module.scss';

interface ICardLeadingIconProps extends ICustomizable {
  children: React.ReactNode;
}

export const CardLeadingIcon = (props: ICardLeadingIconProps) => {
  return (
    <span className={clsx(style['card-leading-icon'], props.className)} role='presentation'>
      {props.children}
    </span>
  );
};
