import CircleCheckIcon from '@ping/assets/Icon/circle-check.svg';
import CircleInfoIcon from '@ping/assets/Icon/circle-info.svg';
import { t } from '@ping/helpers';
import { Button, Spinner } from '@ping/uikit';
import { Tooltip } from '@ping/uikit/TooltipA11Y';
import { getDuoNumber, isNil } from '@ping/utils';
import { QRCodeSVG } from 'qrcode.react';
import { useMemo } from 'react';
import colors from '@ping/assets/scss/theme/exportVariables.module.scss';

import style from './style.module.scss';

import type { QrCodeData } from '@ping/api';

const SPECS_TITLES = [
  'Full name',
  'Full name (English)',
  'Date of birth',
  'ID doc. picture',
  'Selfie',
  'AML check',
  'Proof of address',
  'ID doc. number',
  'Email address',
  'ID doc. issue date',
] as const;

interface IVerificationQrCodeProps {
  isVerificationInProgress: boolean;
  quickResponse: QrCodeData;
  timer: { minutes: number; seconds: number };
  isLoading?: boolean;
}

export const VerificationQrCode = (props: IVerificationQrCodeProps) => {
  const isTimerValid = useMemo(() => !isNaN(props.timer.minutes) && !isNaN(props.timer.seconds), [props.timer]);

  return (
    <section className={style['verification-qr-code']}>
      <div className={style['verification-qr-code__context']}>
        <h3 className={style['verification-qr-code__title']}>{t('Complete your Ping verification')}</h3>
        <p className={style['verification-qr-code__description']}>
          {t(
            'Seems like all required details that are needed to unlock trading are available to be requested. Please, scan the QR code with your CorePass app to continue.'
          )}
        </p>

        <ul className={style['verification-qr-code__specs-list']}>
          {SPECS_TITLES.map(title => (
            <li className={style['verification-qr-code__specs-item']} key={title}>
              <CircleCheckIcon className={style['verification-qr-code__specs-icon']} />
              <span className={style['verification-qr-code__specs-title']}>{t(title)}</span>
            </li>
          ))}
        </ul>
      </div>

      <div className={style['verification-qr-code__presentation']}>
        <div className={style['verification-qr-code__timer']}>
          <strong className={style['timer__title']}>{t('QR code refresh in')}</strong>
          {isTimerValid && (
            <output className={style['timer__time']}>
              {getDuoNumber(props.timer.minutes)}:{getDuoNumber(props.timer.seconds)}
            </output>
          )}
          {!isTimerValid && <Spinner className={style['verification-qr-code__spinner']} size={4} />}
          <Tooltip.Root className={style['timer']}>
            <Tooltip.Trigger className={style['timer__text']}>
              <CircleInfoIcon className={style['timer__icon']} />
            </Tooltip.Trigger>

            <Tooltip.Content className={style['timer-tooltip']}>
              {t(
                "Note that the QR code has a %s min timer. Only one data request can be active at a time during this window. Please, wait until your current request is processed before scanning the QR code again. If you haven't scanned the QR code yet, please do so before the timer expires.",
                props.timer.minutes
              )}
            </Tooltip.Content>
          </Tooltip.Root>
        </div>

        <div className={style['verification-qr-code__contrast']} aria-busy={isNil(props.quickResponse?.link)}>
          <QRCodeSVG
            className={style['verification-qr-code__qr-code']}
            aria-hidden={isNil(props.quickResponse?.link)}
            value={decodeURIComponent(props.quickResponse?.link)}
          />
          {isNil(props.quickResponse?.link) && <Spinner spinnerType='clipLoader' color={colors.PrimaryLight} />}
          {props.isLoading && (
            <div className={style['verification-qr-code__loading-overlay']}>
              <Spinner spinnerType='clipLoader' color={colors.PrimaryLight} />
            </div>
          )}
        </div>
      </div>

      <Button
        className={style['verification-qr-code__send-request']}
        size='large'
        disabled={props.isVerificationInProgress}
      >
        {/* // TODO: bad practice <a> inside a <button> */}
        {!props.isVerificationInProgress && <a href={props.quickResponse?.link}>{t('Send Request')}</a>}

        {props.isVerificationInProgress && (
          <>
            {t('Time left')}&nbsp;
            {isTimerValid && (
              <output>
                {getDuoNumber(props.timer.minutes)}:{getDuoNumber(props.timer.seconds)}
              </output>
            )}
            {!isTimerValid && <Spinner className={style['verification-qr-code__spinner']} size={4} />}
          </>
        )}
      </Button>
    </section>
  );
};
