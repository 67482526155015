import { t } from '@ping/helpers';
import { Toast } from '@ping/uikit';
import clsx from 'clsx';
import React from 'react';
import { usePress } from 'react-aria';

import { CopyableContext } from './copyable.context';
import { CopyableIcon } from './CopyableIcon';
import style from './style.module.scss';

interface ICopyableProps extends ICustomizable {
  title: string;
  text: string;
  children: React.ReactNode;
  onCopy?: () => void;
}

/**
 * It renders a div that, when clicked, copies the text to the clipboard
 * @param {ICopyableProps} props - ICopyableProps
 */
export const Copyable = (props: ICopyableProps) => {
  const { pressProps, isPressed } = usePress({
    isDisabled: !props.text?.length,
    preventFocusOnPress: !props.text?.length,
    onPress: async () => {
      await window.navigator.clipboard.writeText(props.text);
      props.onCopy?.();
      Toast.success({ title: `${props.title} ${t('copied')}`, options: { toastId: props.text } });
    },
  });

  return (
    <div
      {...pressProps}
      className={clsx(style['copyable'], props.className)}
      aria-pressed={isPressed}
      title={props.title + ': ' + props.text}
    >
      <CopyableContext.Provider value={{ isEmpty: !props.text?.length }}>{props.children}</CopyableContext.Provider>
    </div>
  );
};

Copyable.Icon = React.memo(CopyableIcon);
