import { useEffect } from 'react';
import { t } from '@ping/helpers';
import { Button, Modal } from '@ping/uikit';
import { isNil } from '@ping/utils';
import { QRCodeSVG } from 'qrcode.react';

import style from './style.module.scss';
import { ComplianceStepType, getNextStepCompliance, useGetFormAUserInfo } from '@ping/api';
import { useRemoteModal } from '@ping/hooks';
import { modalKeys } from '@ping/constants/modal-keys.constant';
import { NotificationEvents, useNotificationWS } from '@ping/websockets';
import { FormANotificationStatus } from '@ping/websockets/useNotificationWS';

interface ISignFormAModalTrigger {
  onSigned: (nextStep: ComplianceStepType) => void;
}

export const SignFormAModalTrigger = ({ onSigned, ...rest }: ISignFormAModalTrigger) => {
  const { data: formAData } = useGetFormAUserInfo();
  const modal = useRemoteModal(modalKeys.SIGN_FORM_A);
  const { notificationData, notificationCallBack } = useNotificationWS();

  notificationCallBack?.(NotificationEvents.OnFormAStatusChanged);

  const qrCodeLink = formAData?.link;
  const corePassSignLink = formAData?.link;

  const handleCheckNextStep = async () => {
    const nextStep = await getNextStepCompliance();

    if (nextStep !== ComplianceStepType.FormA) {
      onSigned(nextStep);
      modal.state.close();
    }
  };

  useEffect(() => {
    if (notificationData?.status === FormANotificationStatus.SignatureVerified) {
      handleCheckNextStep();
    }
  }, [notificationData]);

  return (
    <Modal.Root {...modal.props}>
      <Modal.Trigger
        onPress={() => {
          modal.state.open();
        }}
        className={style['sign-form-a-modal-trigger']}
        {...rest}
      >
        {t('Agree & Sign')}
      </Modal.Trigger>

      <Modal.Portal className={style['sign-form-a-modal-portal']}>
        <Modal.Header className={style['sign-form-a-modal-portal__header']}>
          <Modal.Title className={style['sign-form-a-modal-portal__title']}>{t('Sign Form')}</Modal.Title>

          <Modal.Subtitle className={style['sign-form-a-modal-portal__subtitle']}>
            {t('Scan QR code with your')}&nbsp;
            <strong className={style['sign-form-a-modal-portal__emphasis']}>{t('CorePass')}</strong>&nbsp;
            {t('app and sign the form in order to continue.')}
          </Modal.Subtitle>

          <Modal.CloseButton className={style['sign-form-a-modal-portal__close']} />
        </Modal.Header>

        <Modal.Content
          className={style['sign-form-a-modal-portal__content']}
          data-device='non-mobile'
          aria-description='the non-mobile devices content'
        >
          <div className={style['sign-form-a-modal-portal__presentation']}>
            <div className={style['sign-form-a-modal-portal__contrast']} aria-busy={isNil(qrCodeLink)}>
              <QRCodeSVG
                className={style['sign-form-a-modal-portal__qr-code']}
                aria-hidden={isNil(qrCodeLink)}
                value={decodeURIComponent(qrCodeLink)}
              />
            </div>
          </div>
        </Modal.Content>

        <Modal.Content
          className={style['sign-form-a-modal-portal__content']}
          data-device='mobile'
          aria-description='the mobile devices content'
        >
          <Modal.Title className={style['sign-form-a-modal-portal__mobile-title']}>
            {t('Sign form in CorePass by clicking the button bellow')}
          </Modal.Title>

          <p className={style['sign-form-a-modal-portal__mobile-description']}>
            {t('To be the most trusted place to buy and sell cryptocurrency and other assets, we utilize')}&nbsp;
            <a className={style['sign-form-a-modal-portal__corepass-link']} href='https://corepass.net'>
              {t('CorePass - decentralized digital identity')}
            </a>
            &nbsp;
            {t(
              'to protect your personal data, secure communications and data transactions, and to comply with the EU’s General Data Protection Regulation (GDPR).'
            )}
          </p>
        </Modal.Content>

        <Modal.Actions className={style['sign-form-a-modal-portal__actions']}>
          <Button className={style['sign-form-a-modal-portal__open-corepass']} size='large'>
            {/* // TODO: bad practice <a> inside <button> */}
            <a href={corePassSignLink}>{t('Open CorePass')}</a>
          </Button>
        </Modal.Actions>
      </Modal.Portal>
    </Modal.Root>
  );
};
