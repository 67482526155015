import { t } from '@ping/helpers';
import { Select } from '@ping/uikit/SelectA11Y';
import { clsx } from 'clsx';
import { useEffect, useState } from 'react';

import style from './style.module.scss';

interface INetworkSelectProps extends ICustomizable {
  assetId?: string;
  selectedKey?: string;
  label?: string;
  isDisabled?: boolean;
  magnet?: IMagnet;
  onChange?(network: string): void;
}

const MOCK_NETWORK = [
  {
    id: 'USDC',
    description: 'Ethereum (ERC token)',
  },
  {
    id: 'CTN',
    description: 'Core (CBC token)',
  },
  {
    id: 'XCB',
    description: 'Core',
  },
  {
    id: 'BTC',
    description: 'Bitcoin',
  },
  {
    id: 'LTC',
    description: 'Litecoin',
  },
  {
    id: 'ETH',
    description: 'Ethereum',
  },
];

export const NetworkSelect = (props: INetworkSelectProps) => {
  const [networksList, setNetworksList] = useState(MOCK_NETWORK); //todo remove mock
  const [selectedNetworkKey, setSelectedNetworkKey] = useState<string>();

  // const { data: contacts, isFetching: isContactsFetching } = useGetAllContactsCryptoContactCrypto({
  //   query: {
  //     enabled: Boolean(props.networkId.id),
  //     select: contacts => {
  //       return contacts.filter(contact => contact.assetId.toLowerCase() === props.assetId.toLowerCase());
  //     },
  //   },
  // });

  // useEffect(() => filterViewItems(''), [isContactsFetching]);

  // const filterViewItems = (value: string) => {
  //   if (isContactsFetching || !contacts) {
  //     return;
  //   }
  //
  //   const text = value.trim().toLowerCase();
  //   const items = contacts.filter(contact => contact.accountName.toLowerCase().includes(text));
  //
  //   setItems(items);
  // };

  useEffect(() => {
    setSelectedNetworkKey('');
    setNetworksList(MOCK_NETWORK.filter(network => network.id.toLowerCase() === props.assetId.toLowerCase()));
  }, [props.assetId]);

  const handleOnSelectionChange = (network: string) => {
    // filterViewItems('');
    props.onChange?.(network);
    setSelectedNetworkKey(network);
  };

  const getNetworkDescription = network => {
    return networksList?.find(networkItem => networkItem.id === network)?.description;
  };

  return (
    <Select
      className={clsx(style['network-select'], props.className)}
      items={networksList}
      isLoading={false}
      selectedKey={selectedNetworkKey}
      isDisabled={props.isDisabled}
      magnet={props.magnet}
      label={props.label}
      onSelectionChange={handleOnSelectionChange}
      emptyState={t('No networks')}
      buttonContent={selectedNetworkKey ? getNetworkDescription(selectedNetworkKey) : t('Select a network')}
      topElement={
        <div>
          <p className={style['network-select__title']}>{t('Select network')}</p>
          <p className={style['network-select__subtitle']}>
            {t(
              'Confirm the network you choose to deposit matches the withdrawal network, otherwise assets will be lost'
            )}
          </p>
        </div>
      }
    >
      {network => (
        <Select.Item className={style['network-select__item']} key={network.id} aria-label={network.id.toUpperCase()}>
          <span className={style['network-select__name']}>{network.id.toUpperCase()}</span>
          <span className={style['network-select__description']}>{network.description}</span>
        </Select.Item>
      )}
    </Select>
  );
};

export default NetworkSelect;
