import { KycCardLayout } from '../KycCard/KycCardLayout';
import ClockIcon from '@ping/assets/Icon/clock.svg';
import { t } from '@ping/helpers';
import { KycCardContactUsSection } from '../KycCardContactSection';
import style from './style.module.scss';

export const KycCardPending = () => {
  return (
    <KycCardLayout headerTitle={t('Pending for review')} HeaderIcon={<ClockIcon />} className={style['kyc-custom']}>
      <div className={style['kyc-pending']}>
        <div className={style['kyc-pending__left-side']}>
          <h6 className={style['kyc-pending__left-side__title']}>{t('Account under review')}</h6>
          <p className={style['kyc-pending__left-side__description']}>
            {t(
              'Our team is working to clarify the issues as soon as possible. During this review process, features and functionalities of your account will be limited. We appreciate your patience and understanding during this period.'
            )}
          </p>
        </div>
        <KycCardContactUsSection />
      </div>
    </KycCardLayout>
  );
};
