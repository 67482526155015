import { t } from '@ping/helpers';

export const validateAmount = (value: number, minWithdrawAmount, balance: number): string => {
  if (value > balance) {
    return t('Balance insufficient');
  }
  if (value && value < minWithdrawAmount) {
    return t('Minimum withdrawal amount is %s', minWithdrawAmount);
  }
  if (value && value >= Number.MAX_SAFE_INTEGER) {
    return t('You have exceeded the number of characters');
  }
  return '';
};
