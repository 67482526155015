import { CardBody } from './CardBody';
import { CardDivider } from './CardDivider';
import { CardHeader } from './CardHeader';
import { CardLeadingIcon } from './CardLeadingIcon';
import { CardRoot } from './CardRoot';
import { CardTitle } from './CardTitle';

export const Card = {
  Root: CardRoot,
  Divider: CardDivider,
  Header: CardHeader,
  Body: CardBody,
  LeadingIcon: CardLeadingIcon,
  Title: CardTitle,
};
