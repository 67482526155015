import { getTime } from '@ping/utils';
import { useEffect, useState } from 'react';

/**
 * @method useCountdown
 * @description Hook to countdown from the provided number.
 * @param targetDate accepts a number in milliseconds
 */

const MILLISECOND = 1_000 as const;

const useCountdown = (targetDate: number) => {
  const [countdown, setCountdown] = useState(targetDate - new Date().getTime());

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown(targetDate - new Date().getTime());
    }, MILLISECOND);

    return () => clearInterval(interval);
  }, [targetDate]);

  // Get the time in days, hours, minutes, etc.
  return getTime(countdown);
};

export { useCountdown };
