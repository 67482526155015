import { Divider } from '@ping/uikit';
import clsx from 'clsx';

import style from './style.module.scss';

type ICardDividerProps = ICustomizable;

export const CardDivider = (props: ICardDividerProps) => {
  return (
    <div className={clsx(style['card-divider'], props.className)}>
      <Divider />
    </div>
  );
};
