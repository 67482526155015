import clsx from 'clsx';

import style from './style.module.scss';

interface ICardRootProps extends ICustomizable {
  children: React.ReactNode;
}

export const CardRoot = (props: ICardRootProps) => {
  return <article className={clsx(style['card-root'], props.className)}>{props.children}</article>;
};
