import PendingIcon from '@ping/assets/Icon/pending.svg';
import { modalKeys } from '@ping/constants/modal-keys.constant';
import { t } from '@ping/helpers';
import { useRemoteModal } from '@ping/hooks';
import { ButtonA11Y, Modal } from '@ping/uikit';

import router from 'next/router';
import style from './style.module.scss';

export const TransferOnProcessingModal = () => {
  const modal = useRemoteModal(modalKeys.TRANSFER_ON_PROCESSING);

  const handleOnViewHistory = async () => {
    modal.state.close();
    router.push('/user/history?view=withdrawals');
  };

  return (
    <Modal.Root {...modal.props}>
      <Modal.Portal className={style['transfer-on-processing-modal']}>
        <Modal.CloseButton className={style['transfer-on-processing-modal__close']} onPress={modal.state.close} />

        <Modal.Content className={style['transfer-on-processing-modal__content']}>
          <PendingIcon className={style['transfer-on-processing-modal__icon']} />
          <Modal.Title className={style['transfer-on-processing-modal__title']}>
            {t('Your transfer is processing')}
          </Modal.Title>
          <Modal.Subtitle className={style['transfer-on-processing-modal__description']}>
            {t('No need to wait here, we will notify you once this transfer status changes.')}
          </Modal.Subtitle>
        </Modal.Content>

        <Modal.Actions className={style['transfer-on-processing-modal__actions']}>
          <ButtonA11Y className={style['transfer-on-processing-modal__continue']} onPress={handleOnViewHistory}>
            {t('View history')}
          </ButtonA11Y>
        </Modal.Actions>
      </Modal.Portal>
    </Modal.Root>
  );
};
