import { useMemo } from 'react';
import { ButtonA11Y, Modal } from '@ping/uikit';
import { t } from '@ping/helpers';
import { ComplianceStepType } from '@ping/api';
import TickIcon from '@ping/assets/Icon/circle-check.svg';
import ForbiddenIcon from '@ping/assets/Icon/forbidden.svg';
import InfoIcon from '@ping/assets/Icon/circle-warning.svg';
import style from './style.module.scss';

interface IUserActivityStatusModal {
  isOpen: boolean;
  nextStep: ComplianceStepType;
  onContinue: () => void;
}

export const UserActivityStatusModal = ({ isOpen, nextStep, onContinue }: IUserActivityStatusModal) => {
  const USER_ACTIVITY_STATUS_CONTENT = useMemo(
    () => ({
      [ComplianceStepType.Trading]: {
        icon: <TickIcon />,
        title: t('Congratulations!'),
        description: t('Your account is now unlocked so you can start trading with Ping Exchange.'),
        status: 'success',
        buttonText: t('start trading'),
      },
      [ComplianceStepType.Forbidden]: {
        icon: <ForbiddenIcon />,
        title: t('Access forbidden'),
        description: t(
          'As part of our compliance measures and in adherence to international regulations, we are unable to offer our services to individuals residing in Iran, North Korea or Myanmar. We sincerely apologize for any inconvenience this may cause.'
        ),
        status: 'danger',
        buttonText: t('continue'),
      },
      [ComplianceStepType.PrimaryUse]: {
        icon: <InfoIcon />,
        title: t('A few more details are required'),
        description: t(
          'The compliancy department requires a few more details from you to ensure that Ping Exchange adheres to all GAFI standards and regulation.'
        ),
        status: 'warning',
        buttonText: t('continue'),
      },
    }),
    []
  );

  return (
    <Modal.Root isOpen={isOpen}>
      <Modal.Portal className={style['user-activity-status-modal']}>
        <Modal.Content data-status={USER_ACTIVITY_STATUS_CONTENT[nextStep]?.status}>
          {USER_ACTIVITY_STATUS_CONTENT[nextStep]?.icon}
          <Modal.Title className={style['user-activity-status-modal__title']}>
            {USER_ACTIVITY_STATUS_CONTENT[nextStep]?.title}
          </Modal.Title>
          <Modal.Subtitle className={style['user-activity-status-modal__description']}>
            {USER_ACTIVITY_STATUS_CONTENT[nextStep]?.description}
          </Modal.Subtitle>
        </Modal.Content>

        <Modal.Actions className={style['user-activity-status-modal__actions']}>
          <ButtonA11Y onPress={onContinue} className={style['user-activity-status-modal__actions-button']}>
            {USER_ACTIVITY_STATUS_CONTENT[nextStep]?.buttonText}
          </ButtonA11Y>
        </Modal.Actions>
      </Modal.Portal>
    </Modal.Root>
  );
};
